import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Crnopjega from './RazaPng/Crnopjega.webp';
import Crnoziga from './RazaPng/Crnoziga.webp';
import Kamenica from './RazaPng/Kamenica.webp';
// import Klinka from './RazaPng/Klinka.webp';
import Modropjega from './RazaPng/Modropjega.webp';
import RazaJezinka from './RazaPng/RazaJezinka.webp';
import Smeda from './RazaPng/Smeda.webp';
import Tuponska from './RazaPng/Tuponska.webp';
import Vijosaraka from './RazaPng/Vijosaraka.webp';
import VolinaBjelica from './RazaPng/VolinaBjelica.webp';
import VolinaMrkulja from './RazaPng/VolinaMrkulja.webp';
import Zvjezdopjega from './RazaPng/Zvjezdopjega.webp'
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image :Crnopjega,
    name: "Raža Crnopjega",
  },
  {
    image :Crnoziga,
    name: "Crnožiga raža",
  },
  {
    image: Kamenica,
    name: "Raža kamenica",
  },
  {
    image: Modropjega,
    name: "Raža Modropjega",
  },
  // {
  //   image: Klinka,
  //   name: "Raža Klinka",
  // },

  {
    image: RazaJezinka,
    name: "Raža Ježinka",
  },
  {
    image: Smeda,
    name: "Raža smeđa",
  },
  {  
    image: Tuponska,
    name: "Raža Tuponska",
  },
  {  
    image: Vijosaraka,
    name: "Raža Vijošaraka",
  },
  {  
    image: VolinaBjelica,
    name: "Raža Volina bjelica",
  },
  {  
    image: VolinaMrkulja,
    name: "Raža Volina Mrkulja",
  },

  {  
    image: Zvjezdopjega,
    name: "Raža Zvjezdopjega",
  },
];

function Raze() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white" >Raže</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri raža</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p>

      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"10880452434"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default Raze;