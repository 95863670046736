import React from 'react';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import Sredozemna from './SredozemnaMedvjedenicaPng/Sredozemna.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
    {
      image: Sredozemna,
      name: "Sredozemna medvjednica",
    },
  ];
  
  function Sredozemno() {
    return (
      <>
      <Navbar/>
      <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
          <div className="position-relative d-flex align-items-center justify-content-center">
            <h1 className="display-1 text-uppercase text-white" >Sredozemne medvjednice</h1>
            <h1 className="position-absolute text-uppercase text-primary">Primjer Sredozemne mevjednice</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slide text-center">
                <div className="testimonial-content-wrapper">
                  <img src={areas[0].image} alt={areas[0].name} className="testimonial-image" />
                  <h2 className="testimonial-name">{areas[0].name}</h2>
                </div>
              </div>
              <Ribe existingFolderId={"11069080719"}/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      </>
    );
  }
  
  export default Sredozemno;