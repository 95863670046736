import { useNavigate } from "react-router-dom";
import { GetServiceName, ServiceInfo, ServiceNames, UserContext } from "../../Utilis"
import AppSquare from './Apps/AppSquare';
import { useContext } from "react";

function AppDisplay() {
  const { user } = useContext(UserContext);
  const navigator = useNavigate();

  const handleAppClick = (appName) => {
      const serviceName = GetServiceName(appName);
      if (!user[serviceName]) {
          return;
      }
      navigator(ServiceInfo[appName].link_url);
  };

  return (
      <>
          <div className="app-squares">
              {ServiceNames.map((name, index) => (
                  <AppSquare key={index} appName={name} onClick={() => handleAppClick(name)} isAccessible={!!user[GetServiceName(name)]} />
              ))}
          </div>
      </>
  );
}

export default AppDisplay;
