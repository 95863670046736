import React from 'react';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import MorskiStakor from './MorskiStakorPng/MorskiStakor.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
    {
      image: MorskiStakor,
      name: "Morski štakor",
    },
  ];
  
  function Morskistakor() {
    return (
      <>
      <Navbar/>
      <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
          <div className="position-relative d-flex align-items-center justify-content-center">
            <h1 className="display-1 text-uppercase text-white" >Morski štakor</h1>
            <h1 className="position-absolute text-uppercase text-primary">Primjer Morskog Štakora</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slide text-center">
                <div className="testimonial-content-wrapper">
                  <img src={areas[0].image} alt={areas[0].name} className="testimonial-image" />
                  <h2 className="testimonial-name">{areas[0].name}</h2>
                  <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p>
                </div>
              </div>
              <Ribe existingFolderId={"10880449780"}/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      </>
    );
  }
  
  export default Morskistakor;
  