import React from "react";
import { Link } from "react-router-dom";
import "./vrste.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import MorskiPas from "./../../../../../Png/morski_pas.webp";
import MorskeMacke from "./../../../../../Png/morske_macke.webp";
import Sklat from "./../../../../../Png/sklat.webp";
import RazoPas from "./../../../../../Png/razopas.webp";
import Golubovi from "./../../../../../Png/Golubovi.webp";
import Drhtulja from "./../../../../../Png/Drhtulja.webp";
import MorskiStakor from "./../../../../../Png/morski_stakor.webp";
import Raza from "./../../../../../Png/raza.webp";
import Upitnik from "./../../../../../Png/Upitnik.webp";
import Kit from "./../../../../../Png/Kit.webp";
import Kornjace from "./../../../../../Png/Kornjaca.webp";
import Sredozemna from "./../../../../../Png/Sredozemna_medvjednica.webp";
import Komercijalna from "./../../../../../Png/Komercijalna.webp";
import Rijetka from "./../../../../../Png/Rijetka.webp";
import Footer from "./Footer/Footer";

const SquareGrid = () => {
  const squares = [
    { id: 1, imgSrc: MorskiPas, text: "MORSKI PSI", link: "/ribe/morski_pas" },
    { id: 2, imgSrc: MorskeMacke, text: "MORSKE MAČKE", link: "/ribe/morska_macka" },
    { id: 3, imgSrc: Sklat, text: "SKLAT", link: "/ribe/sklat" },
    { id: 4, imgSrc: RazoPas, text: "RAŽOPAS", link: "/ribe/razopas" },
    { id: 5, imgSrc: Golubovi, text: "GOLUBOVI", link: "/ribe/golubovi" },
    { id: 6, imgSrc: Drhtulja, text: "DRHTULJA", link: "/ribe/drhtulja" },
    { id: 7, imgSrc: MorskiStakor, text: "MORSKI ŠTAKOR", link: "/ribe/morski_stakor" },
    { id: 8, imgSrc: Raza, text: "RAŽA", link: "/ribe/raza" },
    { id: 9, imgSrc: Kit, text: "KITOVI I DUPINI", link: "/ribe/kitovi" },
    { id: 10, imgSrc: Kornjace, text: "KORNJAČE", link: "/ribe/kornjace" },
    { id: 11, imgSrc: Sredozemna, text: "SREDOZEMNA MEDVJEDNICA", link: "/ribe/sredozemna_medvjednica" },
    { id: 12, imgSrc: Komercijalna, text: "KOMERCIJALNE VRSTE", link: "/ribe/komercijalne_vrste" },
    { id: 13, imgSrc: Rijetka, text: "RIJETKE VRSTE", link: "/ribe/rijetke_vrste" },
    { id: 14, imgSrc: Upitnik, text: "NEPOZNATE", link: "/ribe/nepoznate_ribe" }
  ];

  return (
    <div>
      <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#ffffff" }}>Baza Riba Jadrana</h1>
      <div className="yellow-line"></div> 
      <p className="smallerText">Sve prikupljene fotografije će se koristiti isključivo za trening softvera za automatsko prepoznavanje porodica, roda i vrsta riba iz fotografija. Neće se upotrebljavati u komercijalno/marketinške svrhe niti objavljivati.</p>      
      <div className="photoGrid">
        {squares.map((square) => (
          <Link key={square.id} to={square.link} className="photoItem">
            <p>{square.text}</p>
            <div className="image-container">
              <img src={square.imgSrc} alt={square.text}/>
              <div className="overlay-new">
                <button style={{
                  background: 'transparent',
                  border: '2px solid white',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  outline: 'none'
                }}>
                  <span style={{ marginRight: '5px', fontSize:'20px' }}>Upload</span>
                  <FontAwesomeIcon icon={faCloudArrowUp} />
                </button>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default SquareGrid;
