import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { API_URL } from "../../../../Api/PCloudAPI"
import "./BiomassCageReportStyle.css"
import Plot from 'react-plotly.js';


function BiomassCageReport() {
  const [cageReports, setCageReports] = useState([])
  const [timelineWidth, setTimelineWidth] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null)
  const slidecontainer = useRef(null)
  const [dataHeatmap, setDataHeatmap] = useState([]);
  const [layoutHeatmap, setLayoutHeatmap] = useState({});
  const [imageSrcHeatmap, setImageSrcHeatmap] = useState('');
  const [toggleImages, setToggleImages] = useState(true);
  const imageRef = useRef(null);
  const abortControllerCageRef = useRef(null)
  const abortControllerRef = useRef(null)
  const { cageId } = useParams()
  const [dataCoverage, setDataCoverage] = useState(null)
  const [layoutCoverage, setLayoutCoverage] = useState(null)
  const [coveragePercent, setCoveragePercent] = useState(null)

  useEffect(() => {
    axios.get(`${API_URL}/getCage/${cageId}`).then((res) => {
      setCageReports(res.data)
      setSelectedDate(res.data[res.data.length - 1])
    }).catch((err) => {
      setSelectedDate(null)
    })
    const observer = new ResizeObserver(entries => {
      setTimelineWidth(entries[0].contentRect.width)
    })
    observer.observe(slidecontainer.current)
    return () => slidecontainer.current && observer.unobserve(slidecontainer.current)
  }, [])


  useEffect(() => {
    if (!selectedDate) return
    // Abort any ongoing requests
    if (abortControllerCageRef.current) {
      abortControllerCageRef.current.abort();
    }
    setImageSrcHeatmap("")
    let cleanupCallback;
    // Create a new abort controller for the current request
    const abortController = new AbortController();
    abortControllerCageRef.current = abortController;
    axios.get(`${API_URL}/getReportData/${selectedDate.reportId}`, {
      signal: abortControllerCageRef.current.signal
    }).then((response) => {

      const safeEval = new Function('extractedData', response.data + ';let values={}\n ;typeof(coverage_data)!="undefined"?values={csv_data,points_info,coverage_data,coverage_percent }:values={csv_data,points_info}; return values');
      const values = { ...safeEval() }
      const { csv_data, points_info } = values
      if ((typeof (values.coverage_data) != "undefined") &&typeof (values.coverage_percent) != "undefined") {
          setCoveragePercent(values.coverage_percent)
          
          if ("requestIdleCallback" in window) {
            cleanupCallback = window.requestIdleCallback(() => {
              coverageMap(values.coverage_data);
            });
          } else {
            cleanupCallback = setTimeout(() => {
              coverageMap(values.coverage_depths, values.coverage_headings, values.w, values.h);
            }, 1000);
          }
         
            
      } else {
        setDataCoverage(null)
        setLayoutCoverage(null)
      }
      heatmapMap(csv_data.HEADING, csv_data.DEPTH, csv_data.FR, points_info)
    }).catch((err) => {
      // setSelectedDate(null)
      setImageSrcHeatmap(null)
      setDataHeatmap(null)
      setLayoutHeatmap(null)
      setDataCoverage(null)
      setLayoutCoverage(null)
    })
    return () => {
      if (abortControllerCageRef.current) {
        abortControllerCageRef.current.abort();
      }
      // Cleanup the timeout or idle callback
    if (cleanupCallback) {
      if ("requestIdleCallback" in window) {
        window.cancelIdleCallback(cleanupCallback);
      } else {
        clearTimeout(cleanupCallback);
      }
    }

    };

  }, [selectedDate])


  function handleHover(event) {
    try {
      // Abort previous request if any
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // create a new AbortController
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const pointIndex = event.points[0];
      if (toggleImages && pointIndex.customdata && pointIndex.customdata.img_paths) {

        axios.post(`${API_URL}/getReportImage`, {
          folderId: selectedDate.reportId,
          imgName: pointIndex.customdata.img_paths
        },
          {
            signal: abortController.signal,
            responseType: 'blob'
          }).then((response) => {
            if (!response.data) return
            const url = URL.createObjectURL(response.data);
            setImageSrcHeatmap(url);
          }).catch((ex) => {
          })

      } else {
        setImageSrcHeatmap('');
      }
    } catch (ex) {

    }
  };

  const handleClick = (event) => {
    const pointIndex = event.points[0];
    if (toggleImages && pointIndex.customdata && pointIndex.customdata.img_paths) {
      toggleFullScreen(imageRef.current);
    }
  };

  const toggleFullScreen = (element) => {
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };
  function showDate(date_for_formating) {
    const date = new Date(date_for_formating);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
  }
  const coverageMap=async(coverage_data, show_traces = false) =>{
    console.log(coverage_data.length)
    const scatterTrace = {
      x: coverage_data.map(data => data["heading"]),
      y: coverage_data.map(data => data["depth"]),
      opacity: 1,
    };
    setDataCoverage([
      {
        // x:coverage_headings,y:coverage_depths,
        type: 'scattergl',
        mode: 'markers',
        name: 'Datapoints of supposed covered area',
        // hovertext: coverage_names,
      }, show_traces ? scatterTrace : {}
    ]);
    const shapes = coverage_data.map((data, i) => {

      let x0=data["x0"]
      let y0=data["y0"]
      let x1=data["x1"]
      let y1=data["y1"]
      return {
      type: 'rect',
      x0: x0,
      y0: y0,
      x1: x1,
      y1: y1,
      line: {
        color: '#ADD8E6',
        width: 0
      },
      fillcolor: '#ADD8E6',
      layer: "below"
    }});
    setLayoutCoverage({
      title: {
        text: "Coverage",
        y: 1.5,
        x:0.5,
        xanchor: 'center',
        yanchor: 'top',
      },
      font: { size: 18 },
      // plot_bgcolor: "#cef5f4",
      xaxis: {
        title: {text:'Heading',
        font: {
          size: 26
        }},
        tickmode: 'array',
        tickvals: [0,30,60, 90,120,150, 180,210,240, 270,,300,330, 360],
        range:[0,360],
        // ticktext: ['N', 'E', 'S', 'W', 'N'],
        showgrid: false
      },
      yaxis: {
        title: {
          text:'Depth [m]',
          font:{
          size:22}
        },
        tickvals: [0, -5, -10, -15],
        ticktext: ['0', '-5', '-10', '-15'],
        range: [-15,0],
        zeroline: false,
        showgrid: false,
        autorange: false
      },
      showlegend: false,
      legend: {
        x: 1,
        y: 1,
        bgcolor: 'rgba(255, 255, 255, 0.5)',
        bordercolor: 'rgba(0, 0, 0, 0.5)',
        borderwidth: 1
      },
      autosize: true,
      shapes:[{
        type: 'rect',
        x0:0,
        y0:0,
        x1:360,
        y1:-15,
        line: {
          color: '#cef5f4',
          width: 0
        },
        fillcolor: '#cef5f4',
        layer: "below"
      },...shapes,{ type: 'line', x0: 0, y0: 10, x1: 0, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 90, y0: 10, x1: 90, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 180, y0: 10, x1: 180, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 270, y0: 10, x1: 270, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 360, y0: 10, x1: 360, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        ],
        annotations: [
          { x: 0, y: 0, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 90, y: 0, xref: 'x', yref: 'y', text: 'E', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 180, y:0, xref: 'x', yref: 'y', text: 'S', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 270, y: 0, xref: 'x', yref: 'y', text: 'W', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 360, y: 0, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' }
        ]
    });

  }
  function heatmapMap(Heading, Depth, FR, points_info) {
    const heatmapTrace = {
      x: Heading,
      y: Depth,
      z: FR,
      type: 'heatmap',
      colorscale: [
        [0.0, '#006937'],
        [0.25, '#86CB66'],
        [0.5, '#FFFFBE'],
        [0.75, "#F99455"],
        [1.0, '#A60126']
      ],
      zsmooth: 'best',
      zmin: 0,
      zmax: 10,
      colorbar: { title: 'Fouling<br>ratio' },
      showscale: true,
      hovertemplate: 'Heading: %{x}<br>Depth: %{y}<br>FR: %{z:.1f}<extra></extra>'
    };

    const scatterTrace = {
      x: points_info.map(p => p.x),
      y: points_info.map(p => p.y),
      mode: 'markers',
      customdata: points_info,
      name: 'Point',
      hovertemplate: 'Heading: %{customdata.x}<br>Depth: %{customdata.y} <br>FR: %{customdata.z:.1f}<extra></extra>'
    };

    const layout = {
      title: {
        text: "Heatmap",
        y: 1.5,
        x:0.5,
        xanchor: 'center',
        yanchor: 'top'
      },
      autosize: true,
      plot_bgcolor: 'rgba(0,0,0,0)',
      font: { size: 18 },
      xaxis: { title: {text:'Heading',
        font: {
          size: 26
        }}, tickmode: 'array', tickvals: Heading, ticktext: Heading, showgrid: false, zeroline: false },
      yaxis: { title: {
        text:'Depth [m]',
        font:{
        size:22}
      }, range: [-15, 0], showgrid: false, zeroline: false },
      shapes: [
        { type: 'line', x0: 0, y0: 10, x1: 0, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 90, y0: 10, x1: 90, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 180, y0: 10, x1: 180, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 270, y0: 10, x1: 270, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
        { type: 'line', x0: 360, y0: 10, x1: 360, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } }
      ],
      annotations: [
        { x: 0, y: 10, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
        { x: 90, y: 10, xref: 'x', yref: 'y', text: 'E', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
        { x: 180, y: 10, xref: 'x', yref: 'y', text: 'S', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
        { x: 270, y: 10, xref: 'x', yref: 'y', text: 'W', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
        { x: 360, y: 10, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' }
      ]
    };

    setDataHeatmap([heatmapTrace, scatterTrace]);
    setLayoutHeatmap(layout);

  }
  return (<div className="biofouling-heatmap-report">
    <div className="title-container">
      <img width="120px" src="/noTitle_bio.png" alt="Biofouling Logo" />
      <h1 className="title"><span style={{ color: '#DBC607' }}>BioFOULING</span></h1>
    </div>
    <h1 style={{ color: '#f8f8f8', fontSize: '5rem', textAlign: 'center', marginBottom: '2rem' }}>Cage timeline</h1>
    {cageReports && <div ref={slidecontainer} className="slidecontainer">
      <hr style={{ position: "absolute", top: "50%", width: "100%", border: "1px solid #dac625", transform: "translateY(-50%)" }} />
      {timelineWidth && cageReports.map((report, index) => {
        let left_margin = 1
        let total_margin = 1
        const curr_date = new Date(report.reportDate)
        const first_date = new Date(cageReports[0].reportDate)
        const last_date = new Date(cageReports[cageReports.length - 1].reportDate)
        if (cageReports.length > 1)
          left_margin = curr_date - first_date
        if (cageReports.length > 1)
          total_margin = last_date - first_date
        return (<div key={index} style={{ "left": (left_margin / total_margin) * slidecontainer.current.offsetWidth, "flexDirection": index % 2 ? "column" : "column-reverse" }} className="report_time">
          <p onClick={() => setSelectedDate(report)}>{report.reportDate.split("T")[0].split("-")[2]} . {report.reportDate.split("T")[0].split("-")[1]}</p>
          <div style={{ border: "1px solid #dac625", height: "30%" }} ></div>
          <div onClick={() => setSelectedDate(report)} className="report_time_link">
            <div></div>
          </div>
        </div>)
      })}
    </div>}
    <div className="reportInfo_heatmap">
      {selectedDate ? <div className="report-info">
        <div className="info">
          <p><span className="info-title">Date: </span><span className="info-content">{showDate(selectedDate.reportDate)}</span></p>
        </div>
        <div className="info">
          <p><span className="info-title">Location: </span><span className="info-content">{selectedDate.location}</span></p>
        </div>
        <div className="info">
          <p><span className="info-title">Cage: </span><span className="info-content">{selectedDate.cageName}</span></p>
        </div>
      </div> : null}
      {dataHeatmap ? <div className="heatmap">
        <div id="image_container">
          <h2 id="image-info" style={{ display: imageSrcHeatmap ? 'block' : 'none' }}></h2>
          <img ref={imageRef} id="image" width="100%" src={imageSrcHeatmap} alt="Corresponding" style={{ display: imageSrcHeatmap ? 'block' : 'none' }} />
        </div>
        <div className="showImg-toggle">
          <label> <p>Show images </p></label>
          <label className="toggle-switch">
            <input type="checkbox" id="toggle" checked={toggleImages} onChange={() => setToggleImages(!toggleImages)} />
            <span className="slider"></span>
          </label>
        </div>
        <div id="container">
          <Plot
            data={dataHeatmap}
            layout={layoutHeatmap}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
            onHover={(e) => handleHover(e)}
            onClick={handleClick}
            config={{ displayModeBar: false }}
          />
        </div>
      </div> : null}
    </div>
    <div className="image-info">
      <img style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }} width="60%" src="/FR_scale3.png" alt="FR Scale" />
      <img style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }} width="40%" src="/image.png" alt="Image" />
    </div>
    {dataCoverage && layoutCoverage ?
      <div className="coverage">
        <Plot data={dataCoverage} layout={layoutCoverage} config={{ displayModeBar: false, staticPlot: false, }} style={{ width: '100%', height: '450px' }} />
        <div className="coverage_info">
          <div className="coverage_info_percent">
            <p>Percentage of covered cage/net area</p>
            <p className="coverage_info_percent_data">{coveragePercent}%</p>
          </div>
          <div className="coverage_info_legend">
            <p><span className="uncovered" ></span>Uncovered cage/net area</p>
            <p><span className="covered"></span>Covered with ROV insprection</p>
          </div>
        </div>
      </div> : null}
    <div style={{ marginBottom: '80px' }}>
      <h3 style={{ textAlign: 'center', color: '#f8f8f8', fontSize: '3rem' }}>Description</h3>
      <p style={{ textAlign: 'center', marginBottom: '20px', color: '#f8f8f8' }}>
        Circle cage is unfolded and looks like four connected squares, where each square represents one side of the world (North, East, South, West)
      </p>

    </div>
  </div>)
}
export default BiomassCageReport