import React, { useState,useEffect,useContext } from 'react';
import "./FileUploadCircles.css";
import DragDropBiofouling from './DragDropBiofouling';
import { UserAPI } from '../../../../Api/UserAPI';
import { UserContext } from '../../../../Utilis';

const FileUploadCircles = () => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const [activeCircle, setActiveCircle] = useState(null);
  const { user,setUser } = useContext(UserContext);
  const [cages,setCages]=useState([]);
  
  useEffect(()=>{
    UserAPI.getAllCages(user.Id).then((res)=>{
      setCages(res.data)
    })
  },[])

  const handleFileChange = (id, event) => {
    const file = event.target.files[0];
    setSelectedFiles(prevState => ({
      ...prevState,
      [id]: file
    }));
  };

  const handleCircleClick = (id) => {
    setActiveCircle(id);
  };

  const closeModal = () => {
    setActiveCircle(null);
  };

  return (
    <div className="file-upload-circles">
      <h1 className="header">Choose your cage</h1>
      <div className="circle-container">
        {cages.length>0?cages.map((cage,index)=> 
          
            <div
              key={index}
              className="circle-wrapper"
              onClick={() => handleCircleClick(index)}
            >
              <div className="circle dashed-inner"></div>
              <div className="circle solid-middle"><p>{cage.cageName.split(" ")[cage.cageName.split(" ").length-1]}</p></div>
              <div className="circle dashed-outer"></div>
          
              
            </div>
          ):null}
      </div>

      {activeCircle !== null && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button onClick={closeModal} className="close-modal">X</button>
            <DragDropBiofouling cageId={cages[activeCircle].Id} cageFolderId={cages[activeCircle].folderId} cageName={cages[activeCircle].cageName} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadCircles;