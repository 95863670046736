import React from 'react';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
    {
      name: "Nepoznate i ostale ribe",
      
    },
  ];
  
  function Nepoznato() {
    return (
      <>
      <Navbar/>
      <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{  padding: "20px", borderRadius: "10px" }}>
          <div className="position-relative d-flex align-items-center justify-content-center">
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slide text-center">
                <div className="testimonial-content-wrapper">
                  <h2 className="testimonial-name">{areas[0].name}</h2>
                  <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>U nepoznate i ostale ribe dodajete ribe za koje niste sigurni u koju kategoriju pripadaju</p>
                </div>
              </div>
              <Ribe existingFolderId={"10880453897"}/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      </>
    );
  }
  
  export default Nepoznato;
