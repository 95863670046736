import axios from "axios";
import {SHA1} from "crypto-js"
// export const API_URL=process.env.REACT_APP_API_URL;
export const API_URL="https://my.bluedatab.com/api";
const PCLOUD_URL="https://eapi.pcloud.com"
export const PCloudAPI={
    getDigest:()=>{
        return axios.get(PCLOUD_URL+"/getdigest",{headers:{"user_authorization":undefined}})
    },
    createUser:(user)=>{
        return axios.post(`${API_URL}/createUser`,user)
    },
    createFolder:(_accessToken,folderId,folderName)=>{
        return axios.get(`${PCLOUD_URL}/createfolderifnotexists?auth=${_accessToken}&folderid=${folderId}&name=${folderName}`,{headers:{"user_authorization":undefined}})
    },
    createService:(data)=>{
        return axios.post(`${API_URL}/createService`,data)
    },
    createVideoUpload:(data)=>{
        return axios.put(API_URL+"/addUpload",data)
    },
    upload_file:async (auth,folderId,file,setUploadProgress,index,signal)=>{
        const formData = new FormData();
        formData.append('file', file);
        return  axios.post(PCLOUD_URL+"/uploadfile?auth="+auth+"&nopartial=1&folderid="+folderId+"&filename="+file.name, formData, {
           signal:signal,
            headers: {
                'Content-Type': 'multipart/form-data',
                "user_authorization":undefined
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(prev=>({...prev,[index.toString()]:percentCompleted}))
               
            }
        });
    },
    login:()=>{            
            return axios.get(`${API_URL}/getToken`)
    },
    
}