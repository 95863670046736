import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import Kostelj from './MorskiPasPng/Kostelj.webp';
import Modrulj from './MorskiPasPng/Modrulj.webp';
import Psina from './MorskiPasPng/Psina.webp';
import Glusac from './MorskiPasPng/Glusac.webp';
import Cukov from './MorskiPasPng/Cukov.webp';
import Zvjezdas from './MorskiPasPng/Zvjezdas.webp';
import Prasac from './MorskiPasPng/Prasac.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image : Kostelj,
    name: "Kostelj Dubinac",
  },
 
  {
    image: Modrulj,
    name: "Pas Modrulj",
  },
  {
    image: Psina,
    name: "Velika bijela psina",
  },
  {
    image: Glusac,
    name: "Pas Glušac",
  },
  {
    image: Cukov,
    name: "Pas Čukov",
  },
  {  
    image: Zvjezdas,
    name: "Pas Zvjezdaš",
  },
  {  
    image: Prasac,
    name: "Pas Prasac",
  },
];

function MorskiPsi() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white">Morski Psi</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri morskih pasa</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p>
      </div>
    </div>
  ))}
</Slider>
          </div>
          <Ribe existingFolderId={"10880436926"}/>
      </div>
      <Footer/>
    </div>
    </>
  );
}
export default MorskiPsi;
