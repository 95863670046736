import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from './../../../../../../Png/logo.webp'; 
import "./FooterRibe.css";

function Footer() {
    return (
        <footer className="novi">
            <div className="logo-container">
                <a href="https://bluedatab.com" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="BlueDataB Logo" className="bluedatab-logo" />
                </a>
            </div>
            <p>Sva prava na izradu softvera zadržava BlueDataB</p>

            <ul className="social-icons">
                    <a href="https://www.facebook.com/KARMENstudioAI" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://wa.me/385989596431" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                    <a href="https://www.instagram.com/ai.ribe_jadrana/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://x.com/i/flow/login?redirect_after_login=%2FKarmenstudioAI" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.linkedin.com/company/karmenstudio/mycompany/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
            </ul>
        </footer>
    );
}

export default Footer;