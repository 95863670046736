import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arbun from './KomercijalnePng/Arbun.webp';
import Bezmek from './KomercijalnePng/Bezmek.webp';
import Bukva from './KomercijalnePng/Bukva.webp';
import CipalBatas from './KomercijalnePng/CipalBatas.webp';
import Fratar from './KomercijalnePng/Fratar.webp';
import Gavun from './KomercijalnePng/Gavun.webp';
import GiraOblica from './KomercijalnePng/GiraOblica.webp';
import Gof from './KomercijalnePng/Gof.webp';
// import Grdobina from './KomercijalnePng/Grdobina.webp';
import Incun from './KomercijalnePng/Incun.webp';
import KokoticBarjaktar from './KomercijalnePng/KokoticBarjaktar.webp';
import Komarca from './KomercijalnePng/Komarca.webp';
import Kovac from './KomercijalnePng/Kovac.webp';
import Lokarda from './KomercijalnePng/Lokarda.webp';
import Lubin from './KomercijalnePng/Lubin.webp';
import Pagar from './KomercijalnePng/Pagar.webp';
import Palamida from './KomercijalnePng/Palamida.webp';
import Papalina from './KomercijalnePng/Papalina.webp';
import PaukMrkulj from './KomercijalnePng/PaukMrkulj.webp';
import Pic from './KomercijalnePng/Pic.webp';
import Salpa from './KomercijalnePng/Salpa.webp';
import Skusa from './KomercijalnePng/Skusa.webp';
// import Spar from './KomercijalnePng/Spar.webp';
import Srdela from './KomercijalnePng/Srdela.webp';
// import Strijelka from './KomercijalnePng/Strijelka.webp';
import TrljaBlatarica from './KomercijalnePng/TrljaBlatarica.webp';
import Tunj from './KomercijalnePng/Tunj.webp';
import Ugor from './KomercijalnePng/Ugor.webp';
import Zubatac from './KomercijalnePng/Zubatac.webp';
import ZubatacKrunas from './KomercijalnePng/ZubatacKrunas.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image :Arbun,
    name: "Arbun",
  },
  
  {
    image: Bezmek,
    name: "Bezmek",
  },
  {
    image: Bukva,
    name: "Bukva",
  },
  {
    image: CipalBatas,
    name: "Cipal Bataš",
  },
  {  
    image: Fratar,
    name: "Fratar",
  },
  {  
    image: Gavun,
    name: "Gavun",
  },
  {  
    image: GiraOblica,
    name: "Gira Oblica",
  },
  {  
    image: Gof,
    name: "Gof",
  },
  // {
  //   image :Grdobina,
  //   name: "Grdobina",
  // },
  {
    image: Incun,
    name: "Inčun",
  },

  {
    image: KokoticBarjaktar,
    name: "Kokotić Barjaktar",
  },
  {
    image: Komarca,
    name: "Komarča",
  },
  {  
    image: Kovac,
    name: "Kovač",
  },
  {  
    image: Lokarda,
    name: "Lokarda",
  },
  {  
    image: Lubin,
    name: "Lubin",
  },
  {  
    image: Pagar,
    name: "Pagar",
  },
  {  
    image: Palamida,
    name: "Palamida",
  },
  {  
    image: Papalina,
    name: "Papalina",
  },
  {  
    image: PaukMrkulj,
    name: "Pauk Mrkulj",
  },
  {  
    image: Pic,
    name: "Pic",
  },
  {  
    image: Salpa,
    name: "Salpa",
  },

  {  
    image: Skusa,
    name: "Skuša",
   },
  // {  
  //   image: Spar,
  //   name: "Špar",
  // },
  {
    image: Srdela,
    name: "Srdela",
  },
  // {  
  //   image: Strijelka,
  //   name: "Strijelka",
  // },
  {  
    image: TrljaBlatarica,
    name: "Trlja Blatarica",
  },
  {  
    image: Tunj,
    name: "Tunj",
  },
  {  
    image: Ugor,
    name: "Ugor",
  },
  {  
    image: Zubatac,
    name: "Zubatac",
  },
  {  
    image: ZubatacKrunas,
    name: "Zubatac Krunaš",
  },
];

function Rijetka() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white" >Komercijalne vrste</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri komercijalnih vrsta</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 163-452</p>

      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"11069082042"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}
export default Rijetka;